import styled from '@emotion/styled'
import useMediaQuery from 'app/utils/useMediaQuery'
import React, { memo } from 'react'

export interface Props {
  ID?: string
}
export const Vimeo = memo(function Vimeo({ ID }: Props) {
  const isMobile = useMediaQuery('(max-width: 1199px)')

  return (
    <Container>
      <Foreground>
        <IFrame
          allow="autoplay; fullscreen; picture-in-picture"
          id="main-video"
          width="640"
          height="360"
          loading="lazy"
          name="main-video"
          data-cookieconsent="marketing"
          data-cookieblock-src={`https://player.vimeo.com/video/${ID}&background=1&muted=1&quality=${
            isMobile ? 'auto' : '2K'
          }&controls=0`}
          title="Hotel Corallo Sorrento"
        />
      </Foreground>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99;
`

const Foreground = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  @media (min-aspect-ratio: 16/9) {
    height: 300% !important;
    top: -100% !important;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 300% !important;
    left: -100% !important;
  }
`

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-aspect-ratio: 16/9) and (max-width: 767px) {
    width: 140%;
    height: 140%;
    left: -20%;
    top: -20%;
  }
`
