import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  ID?: string
}

export const Youtube = memo(function Youtube({ ID }: Props) {
  if (!ID) {
    return null
  }
  return (
    <Container>
      <Foreground>
        <Iframe
          src={`https://www.youtube.com/embed/${ID}?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&playlist=${ID}&mute=1&playsinline=1&modestbranding=1`}
          frameBorder="0"
        />
      </Foreground>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99;
`

const Foreground = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  @media (min-aspect-ratio: 16/9) {
    height: 350%;
    top: -125%;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 350%;
    left: -125%;
  }
`

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 767px) {
    height: 150%;
  }

  @media (max-width: 574px) {
    height: 110%;
    top: -5%;
  }
`
